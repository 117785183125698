import React, { useEffect, useRef, useState } from 'react';
import ePub from 'epubjs';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { style } from '@mui/system';

const EpubViewer = ({ bookUrl, filename } : {bookUrl: any, filename: any}) => { 
  const viewerRef = useRef(null);
  const [highlighted, setHighlighted] = useState(false);
  const [cfi, setCfi] = useState('');
  const [book, setBook] = useState<ePub.Book>();
  const [rendition, setRendition] = useState<ePub.Rendition>();

  const [epubUrl, setEpubUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [modalVisible, setModalVisible] = useState(false); // New state for modal visibility
  const [selectedUrl, setSelectedUrl] = useState(''); // State to hold the selected URL
  
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [toc, setToc] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
 
  useEffect(() => {
    const fetchPresignedUrl = async () => {
      setLoading(true);
      setError('');
      const BACKENDURL = process.env.PUBLIC_URL
        ? 'https://book-link-backend.onrender.com'
        : 'http://localhost:4000';

      const URLPREFIX = `${BACKENDURL}/api`;
      const FILENAME = filename;
      const url = `${URLPREFIX}/epub/s3-url?fileName=${FILENAME}`;
      console.log('Attempt fetch to url', url);

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const data = await response.json();
        setEpubUrl(data.url);
      } catch (error) {
        console.error('Failed to fetch presigned URL:', error);
        setError('Failed to fetch presigned URL. See console for details.');
      } finally {
        setLoading(false);
      }
    };

    fetchPresignedUrl();
  }, []); // Runs once on mount

  useEffect(() => {
    console.log('epubUrl changed to', epubUrl);
    if (epubUrl && viewerRef.current) {
      console.log('epubUrl and viewerRef.current', epubUrl, viewerRef.current);
      const b = ePub(epubUrl);
      b.open(epubUrl)
        .then(() => {
          console.log('set book to ', b);
          setBook(b);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [epubUrl]);

  const highlightText = (cfi: any) => {
    console.log('Highlighting text at cfi', cfi);
    console.log('rendition', rendition);
    if (rendition && !highlighted) {
      rendition?.annotations.highlight(
        cfi,
        {},
        (e: any) => {
          console.log('Highlight clicked', e.target);
        },
        'highlight',
        { fill: 'yellow', 'fill-opacity': '0.3' },
      );
      setHighlighted(true);
    } else {
      setHighlighted(false);
    }
  };

  useEffect(() => {
    console.log('Enter book useeffect', book);
    console.log('Enter fetchData');
    console.log('viewerRef.current:', viewerRef.current);
    if (book && viewerRef?.current) {
      console.log('updated book', book);
      const rendition = book?.renderTo(viewerRef!.current!, {
        manager: 'continuous',
        flow: 'scrolled',
        width: '100%',
        height: '100%',
      });
      try {
        rendition.start();
      } catch (error) {
        console.log('Issue with rendition.start:', error);
      }
      console.log('Display rendition', rendition);
      const params = new URLSearchParams(window.location.search);
      var cfi = params.get('cfi');
      if (cfi) {
        cfi = decodeURIComponent(cfi).toString();
        cfi += ')';
        console.log('scroll to cfi', cfi);
        setCfi(cfi);
        setHighlighted(false);
        rendition
          ?.display(cfi)
          .then(() => {
            console.log('display rendition cfi', cfi, rendition);
            highlightText(cfi); // Highlight the text after displaying the CFI
          })
          .catch((err: any) =>
            console.error('Error displaying the rendition:', err),
          );
      } else {
      rendition
        .display()
        .then(() => console.log('display rendition executed!!!', rendition))
        .catch((err) => console.error('Error displaying tdhe rendition:', err));
      }
      console.log('set rendition', rendition);
      setRendition(rendition);
    }
  }, [book]);

  book?.loaded.navigation.then(function (toc) {
    console.log('navigation loaded book', book);
  });

  useEffect(() => {
    if (rendition && cfi && !highlighted) {
      highlightText(cfi);
    }
    rendition?.on('selected', function (cfiRange: any, contents: any) {
      book!.getRange(cfiRange).then(function (range) {
        if (range) {
          const selectedText = range.toString();
          console.log('cfiRange', cfiRange, '. Selected: ', selectedText);
          const bookId = bookUrl; // This should uniquely identify the book
          const BACKENDURL = process.env.PUBLIC_URL
        ? process.env.PUBLIC_URL
        : 'http://localhost:3000';
          const url = `${BACKENDURL}/${bookId}?cfi=${encodeURIComponent(
            cfiRange!.toString(),
          )}`;
          console.log(`URL to selected text: ${url}`);
          setSelectedUrl(url); // Update state with the new URL
          setModalVisible(true); // Show the modal
        }
      });
    });
  }, [rendition]);

  const handleClose = () => setModalVisible(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  // Function to copy text to clipboard
  const copyToClipboard = (text: any) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // Optionally, you can display a message confirming the text was copied.
        console.log('Text copied to clipboard');
        handleClose(); // Close the modal after copying
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  useEffect(() => {
    // Fetching presigned URL and opening ePub logic...
    if (book) {
      book.loaded.navigation.then((navigation: any) => {
        setToc(navigation.toc);
      });
    }
  }, [book]);

  const toggleDrawer = (open: any) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const filteredToc = toc.filter((item: any) => item!.label.toLowerCase().includes(searchQuery.toLowerCase()));

  const handleTocClick = (href: any) => {
    rendition?.display(href).then(() => console.log('display rendition NAV executed!!!', rendition))
    .catch((err) => console.error('Error displaying tdhe rendition:', err))
    setDrawerOpen(false); // Close the drawer after selection
  };

  return (
    <div className="App" style={{ width: '100%', height: '100vh' }}>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(!drawerOpen)}
        style={{ position: 'absolute', top: 0, left: 10, zIndex: 1201 }}
      >
        <MenuIcon />
      </IconButton>
      <div ref={viewerRef} style={{ width: '100%', height: '100%' }}></div>
      <Drawer anchor="left" open={drawerOpen} >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          // onKeyDown={toggleDrawer(false)}
        >
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            placeholder="Search in ToC"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <List>
            {filteredToc.map((tocItem: any, index: any) => (
              <ListItem button key={index} onClick={() => handleTocClick(tocItem?.href)}>
                <ListItemText primary={tocItem?.label} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Modal
        open={modalVisible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Copy URL
          </Typography>
          <TextField
            fullWidth
            id="modal-modal-description"
            sx={{ mt: 2 }}
            value={selectedUrl}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
          />
          <Button
            onClick={() => copyToClipboard(selectedUrl)}
            sx={{ mt: 2, mr: 1 }}
            variant="contained"
          >
            Copy to Clipboard
          </Button>
          <Button onClick={handleClose} sx={{ mt: 2 }} variant="contained">
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default EpubViewer;
